@font-face {
  font-family: 'rticon'; /* Project id 2532854 */
  src: url('iconfont.woff2?t=1700644006380') format('woff2'), url('iconfont.woff?t=1700644006380') format('woff'),
    url('iconfont.ttf?t=1700644006380') format('truetype');
}

.rticon {
  font-family: 'rticon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-help::before {
  content: '\e638';
}

.icon-delete::before {
  content: '\e624';
}

.icon-play::before {
  content: '\e616';
}

.icon-stop::before {
  content: '\e615';
}

.icon-pinyin::before {
  content: '\e613';
}

.icon-speed::before {
  content: '\e610';
}

.icon-action::before {
  content: '\e634';
}

.icon-pause::before {
  content: '\e605';
}

.icon-expression::before {
  content: '\e604';
}

.icon-exception::before {
  content: '\e625';
}

.icon-thumbtack::before {
  content: '\e63b';
}
.icon-text-import::before {
  content: '\e60f';
}

.icon-audio-pause:before {
  content: '\e60b';
}

.icon-audio-play:before {
  content: '\e603';
}
.icon-audio-icon:before {
  content: '\e607';
}
.icon-audio-loading:before {
  content: '\e608';
}
.icon-text::before {
  content: '\e642';
}

.icon-text-solid::before {
  content: '\e641';
}

.icon-voice-solid::before {
  content: '\e645';
}

.icon-camera::before {
  content: '\e644';
}

.icon-camera-solid::before {
  content: '\e645';
}
.icon-global-audio-play::before {
  content: '\e616';
}
.icon-global-audio-speed:before {
  content: '\e610';
}
.icon-arrow-down-bold:before {
  content: '\e61c';
}
.icon-pop-arrow-top:before {
  content: '\e630';
}

.icon-audio-make::before {
  content: '\e607';
}

.icon-video-make::before {
  content: '\e632';
}
.icon-text-cover:before {
  content: '\e60e';
}
.icon-text-insert:before {
  content: '\e62c';
}
.icon-copy::before {
  content: '\e60c';
}
.icon-word::before {
  content: '\e644';
}
.icon-sub::before {
  content: '\e642';
}
.icon-edit-video::before {
  content: '\e643';
}
.icon-editor-play::before {
  content: '\e600';
}
.icon-editor-pause::before {
  content: '\e7fe';
}
.icon-asset-active::before {
  content: '\e6b9';
}
.icon-asset-inactive::before {
  content: '\e6b7';
}
