.idh-switch-checked:focus,
.idh-switch:focus {
  box-shadow: none;
}
.idh-slider:not(.idh-slider-disabled) .idh-slider-handle:hover {
  background-color: #f2f4f7;
}
.idh-pagination .idh-pagination-item-active {
  background: #0052d9;
}
.idh-pagination .idh-pagination-item-active a {
  color: #fff;
}
.idh-pagination .idh-pagination-item-link {
  border: none;
}
.idh-pagination .idh-pagination-jump-prev .idh-pagination-item-container .idh-pagination-item-ellipsis,
.idh-pagination .idh-pagination-jump-next .idh-pagination-item-container .idh-pagination-item-ellipsis {
  color: #0f1829;
}
.idh-tooltip {
  border-radius: 6px;
}
.idh-tooltip .idh-tooltip-arrow-content {
  background-color: rgba(0, 0, 0, 0.9);
}
.idh-tooltip .idh-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.9);
}
